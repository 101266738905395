import { createStore } from 'vuex'

export default createStore({
  state: {
    notice: '',
    notice_type: 'alert-danger',
    account: undefined,
    token: '',
    loggedin: false,
    registered: false,
    picture: undefined,
    location: '',
    desire: '',
    maximages: 0,
    takenimages: 0,
    allimages: [],
    showhelp: false,
    help: '',
    qr_code_fullnames: undefined,
    lastupload: '',
    status: ["wird noch überprüft", "veröffentlicht", "abgelehnt"]
  },
  mutations: {
    set_location(state, payload) {
      state.location = payload
    }, register(state, payload) {
      state.loggedin = true
      state.account = payload.account
      state.token = payload.token
      localStorage.setItem('token', payload.token);
      localStorage.setItem('account', JSON.stringify(payload.account));
      let takenimages = 0
      if (typeof payload.takenimages !== 'undefined') takenimages = payload.takenimages
      state.takenimages = parseInt(takenimages)
      localStorage.setItem('takenimages', takenimages)

    }, restdata(state, payload) {
      state.token = payload.token
      state.account = payload.account
      state.takenimages = payload.takenimages
      state.qr_code_fullnames = payload.qr_code_fullnames
    },
    settakenimages(state, payload) {
      let takenimages = 0
      if (payload !== null) takenimages = payload
      state.takenimages = takenimages
      localStorage.setItem('takenimages', takenimages)
    },
    login(state) { state.loggedin = true },
    logout(state) {
      state.loggedin = false
      state.registered = false
    },
    setnotice(state, payload) {
      console.log(payload)
      state.notice = payload.notice
      state.notice_type = payload.notice_type
    }, setpicture(state, payload) {
      state.picture = payload
    }, sethelp(state, payload) { state.help = payload },
    setshowhelp(state, payload) { state.showhelp = payload },

    setallimages(state, payload) {
      state.allimages = payload
    },
    removeimage(state, index) {
      state.allimages.splice(index, 1)
    },
    setmaximages(state, payload) {
      state.maximages = payload
    },
    uploaded(state, payload) {
      state.desire = ""
      state.picture = undefined
      state.qr_code_fullnames = payload.qr_code_fullnames
      state.lastupload = payload.lastupload
      localStorage.setItem('qrcodefullnames', JSON.stringify(state.qr_code_fullnames));
    },
    resetlastupload(state) { state.lastupload = "" }
  },
  actions: {
    restoredata({ commit }) {
      if (localStorage.getItem("token") === null) return
      if (localStorage.getItem("account") === null) return
      if (localStorage.getItem("takenimages") === null) return

      let account = localStorage.getItem("account")
      if (typeof account === 'string') account = JSON.parse(account)

      let qr_code_fullnames = localStorage.getItem("qrcodefullnames")
      if (typeof qr_code_fullnames === 'string') qr_code_fullnames = JSON.parse(qr_code_fullnames)
      else if (qr_code_fullnames === null) qr_code_fullnames = []

      let data = {
        account,
        qr_code_fullnames,
        token: localStorage.getItem("token"),
        takenimages: parseInt(localStorage.getItem("takenimages")),
      }
      commit('restdata', data)
      commit('login')
    }, firenotice({ commit }, payload) {
      commit('setnotice', payload)
      setTimeout(() => {
        commit('setnotice', {
          notice: '',
          notice_type: ''
        })
      }, 5000);
    }
  },
  modules: {
  }
})
