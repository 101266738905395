export const baseURL = "https://app.zugepflastert.de/"
export const MediaURL = "https://zugepflastert.de/media/"

export async function postData(url = '', data, token) {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + token,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: data
    })
    return response.json()
}

export async function uploadData(url = '', data, token) {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Authorization': 'Basic ' + token,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: data
    })
    return response.json()
}