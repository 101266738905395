<template>
  <transition name="overlay">
    <div v-if="show" class="overlay">
      <button @click="$store.commit('setshowhelp', false)"></button>
      <div v-html="help"></div>
    </div>
  </transition>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["show"],
  setup() {
    const store = useStore();
    return {
      help: computed(() => store.state.help),
    };
  },
};
</script>