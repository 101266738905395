import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/:qr',
    name: 'start',
    component: () => import(/* webpackChunkName: "start" */ '../views/start.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/camera',
    name: 'camera',
    component: () => import(/* webpackChunkName: "camera" */ '../views/camera.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import(/* webpackChunkName: "upload" */ '../views/upload.vue')
  },
  {
    path: '/overview',
    name: 'overview',
    alias: '/',
    component: () => import(/* webpackChunkName: "overview" */ '../views/overview.vue')
  },
  {
    path: '/allimages',
    name: 'allimages',
    component: () => import(/* webpackChunkName: "allimages" */ '../views/allimages.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const site = document.querySelector('#site')
  site.className = `site-${to.name}`
  next()
})

export default router
