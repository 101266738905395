<template>
  <div id="info">
    <help :show="showhelp"></help>
    <alert
      :message="notice"
      :type="notice_type"
      :show="notice != '' ? true : false"
    ></alert>
  </div>
  <div id="site">
    <header>
      <span>{{ takenimages }}/{{ maximages }}</span>
    </header>
    <router-view />
    <footer>
      <div id="buttongroupfooter"></div>
      <button
        @click="$store.commit('setshowhelp', true)"
        class="faq-btn"
      ></button>
    </footer>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import alert from "./components/alert.vue";
import help from "./components/help.vue";
import { postData, baseURL } from "./api";

export default {
  components: { alert, help },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("restoredata");

    init();
    async function init() {
      const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/));
   //   if(!isMobile) {
     //   console.log("is not mobile")
       // window.location = "https://zugepflastert.de"
     // }
      let url = baseURL;
      if (store.state.loggedin) url += "api/checkauth";
      else url += "api/init";
      let data = await postData(url, null, store.state.token);
      if (data.status == "error") {
        if (data.message == "Account exertiert nicht") {
          store.dispatch("firenotice", {
            notice: data.message,
            notice_type: "alert-danger",
          });
          store.commit("logout");
          router.push("register")
          init();
        } else if (data.message == "Account ist gesperrt") {
          store.dispatch("firenotice", {
            notice: data.message,
            notice_type: "alert-danger",
          });
        }
      } else {
        if (typeof data.help !== "undefined")
          store.commit("sethelp", data.help);
        if (typeof data.maximages !== "undefined")
          store.commit("setmaximages", data.maximages);
        if (typeof data.takenimages !== "undefined")
          store.commit("settakenimages", data.takenimages);
      }
    }

    return {
      notice: computed(() => store.state.notice),
      showhelp: computed(() => store.state.showhelp),
      notice_type: computed(() => store.state.notice_type),
      maximages: computed(() => store.state.maximages),
      takenimages: computed(() => store.state.takenimages),
    };
  },
};
</script>
