<template>
  <div id="alert">
    <transition name="bounce">
      <p :class="type"  v-if="show" v-html="message">
      </p>
    </transition>
  </div>
</template>
<script>
export default {
  name: "alert",
  props: ["message", "type", "show"],
};
</script>